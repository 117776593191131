<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="articleData === undefined">
      <h4 class="alert-heading">Error fetching article data</h4>
      <div class="alert-body">
        No article found with this article id. Check
        <b-link class="alert-link" :to="{ name: 'apps-articles-list' }">
          Articles List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="articleData">
      <b-row>
        <b-col cols="12" xl="8">
          <b-card>
            <b-row>
              <!-- Left col -->
              <b-col
                cols="21"
                xl="6"
                class="d-flex justify-content-between flex-column"
              >
                <!-- Action Buttons -->
                <div class="d-flex justify-content-start">
                  <div class="d-flex flex-column">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        {{ articleData.title }}
                      </h4>
                    </div>
                    <div  v-if="$can('edit', 'article')">
                      <div
                        class="d-flex flex-wrap"
                        v-if="articleData.status == 'Approved'"
                      >
                        <b-button
                          variant="outline-danger"
                          class=""
                          @click="setArticleStatus('Disapprove')"
                        >
                          Disapprove
                        </b-button>
                      </div>
                      <div
                        class="d-flex flex-wrap"
                        v-if="articleData.status == 'Disapproved'"
                      >
                        <b-button
                          variant="outline-success"
                          class=""
                          @click="approveArticle()"
                        >
                          Approve
                        </b-button>
                      </div>
                      <div
                        class="d-flex flex-wrap"
                        v-if="articleData.status == 'Awaiting Review'"
                      >
                        <b-button
                          variant="outline-success"
                          class=""
                          @click="approveArticle()"
                        >
                          Approve
                        </b-button>
                        <b-button
                          variant="outline-warning"
                          class="ml-1"
                          @click="setArticleStatus('Require Change')"
                        >
                          Require Change
                        </b-button>
                        <b-button
                          variant="outline-danger"
                          class="mt-1"
                          @click="setArticleStatus('Disapprove')"
                        >
                          Disapprove
                        </b-button>
                      </div>
                      <div
                        class="d-flex flex-wrap"
                        v-if="articleData.status == 'Requires Action'"
                      >
                        <b-button
                          variant="outline-success"
                          class=""
                          @click="approveArticle()"
                        >
                          Approve
                        </b-button>
                        <b-button
                          variant="outline-warning"
                          class="ml-1"
                          @click="setArticleStatus('Require Change')"
                        >
                          Require Change
                        </b-button>
                      </div>
                      <br />
                      <div
                        v-if="
                          articleData.admin &&
                          articleData.admin_id == articleData.loggedInUser.data.id
                        "
                        class="d-flex flex-wrap"
                      >
                        <b-button variant="outline-warning" @click="editArticle"
                          >Edit</b-button
                        >
                        <b-button
                          class="ml-1"
                          variant="outline-danger"
                          @click="deleteArticle"
                          >Delete</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-1" v-if="articleData.status_reason != null">
                  <h4>Status reason</h4>
                  {{ articleData.status_reason }}
                </div>
              </b-col>

              <!-- Right Col: Table -->
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="UserIcon" class="mr-75" />
                      <span class="font-weight-bold">Uploaded by</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        articleData.user
                          ? articleData.user.first_name +
                            " " +
                            articleData.user.last_name
                          : articleData.admin.first_name +
                            " " +
                            articleData.admin.last_name
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="MailIcon" class="mr-75" />
                      <span class="font-weight-bold">Email</span>
                    </th>
                    <td class="pb-50">
                      {{
                        articleData.user
                          ? articleData.user.email
                          : articleData.admin.email
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <feather-icon icon="PhoneIcon" class="mr-75" />
                      <span class="font-weight-bold">Contact</span>
                    </th>
                    <td>
                      {{
                        articleData.user
                          ? articleData.user.phone_number
                          : articleData.admin.phone_number
                      }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-card>
          <b-card>
            <div class="d-flex flex-wrap">
              <li
                class="mr-1"
                v-for="category in articleData.categories"
                :key="category.id"
              >
                {{ category.name }}
              </li>
            </div>
          </b-card>
          <b-card>
            {{ articleData.content }}
          </b-card>
        </b-col>
        <b-col cols="12" xl="4">
          <b-card>
            <b-avatar
              :src="articleData.cover_image_url"
              :text="avatarText(articleData.title)"
              size="350px"
              rounded
            />
          </b-card>
        </b-col>
      </b-row>

      <!-- Edit Article Modal -->
      <b-modal
        v-model="editArticleModal"
        hide-footer
        centered
        size="lg"
        title="Edit Article"
      >
        <b-form-group label="Enter the Article's Title" label-for="enterTitle">
          <b-form-input
            id="enterTitle"
            placeholder="Enter the Article's Title"
            v-model="editArticleTitle"
          />
        </b-form-group>

        <b-form-group
          label="Select Article's Categories"
          label-for="selectCategories"
        >
          <div class="demo-inline-spacing">
            <b-form-checkbox
              v-for="category in categories"
              :key="category.value"
              v-model="editArticleCategories"
              :checked="editArticleCategories.includes(category.value)"
              :value="category.value"
            >
              {{ category.text }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group label="Article Content" label-for="articleContent">
          <b-form-textarea
            v-model="editArticleContent"
            placeholder="Enter the content of the article"
            rows="10"
            :state="editArticleContent.length <= maxChar"
            class="char-textarea"
            :class="editArticleContent.length >= maxChar ? 'text-danger' : ''"
          />
          <small
            class="textarea-counter-value float-right"
            :class="editArticleContent.length >= maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ editArticleContent.length }}</span> /
            {{ maxChar }}
          </small>
        </b-form-group>

        <b-form-group
          label="Select the Article's Cover Image"
          label-for="selectFile"
        >
          <b-form-file
            v-model="editArticleImage"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".jpg,.jpeg,.png"
          />

          <b-card-text class="my-1">
            Selected file:
            <strong>{{ editArticleImage ? editArticleImage.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <b-button variant="outline-primary" @click="updateArticle()">
          Submit
        </b-button>
      </b-modal>

      <!-- Enter Status Reason Article Modal -->
      <b-modal
        v-model="enterStatusReasonModal"
        hide-footer
        centered
        size="lg"
        title="Enter Article Status Reason"
      >
        <b-form-group label="Article Comments" label-for="articleComments">
          <b-form-textarea
            v-model="statusReason"
            placeholder="Enter your comments of what needs to be changed"
            rows="5"
          />
        </b-form-group>

        <b-button variant="outline-primary" @click="submitStatus()">
          Submit
        </b-button>
      </b-modal>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BButton,
  BAlert,
  BLink,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BCardText,
  BImg, 
  BMedia, 
  BMediaBody,
} from "bootstrap-vue";
import articleStore from "./articleStore";
import useArticlesList from "./useArticlesList";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BAvatar,
    BButton,
    BRow,
    BCol,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BModal,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BCardText,
    BImg, 
    BMedia, 
    BMediaBody,
  },
  setup() {
    const ARTICLE_APP_STORE_MODULE_NAME = "app-articles";

    const maxChar = ref(1000);

    const categories = [];

    const fetchCategories = () => {
      store
        .dispatch("app-articles/fetchAllCategories")
        .then((response) => {
          response.data.forEach((category) => {
            categories.push({ value: category.id, text: category.name });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    
    // Register module
    if (!store.hasModule(ARTICLE_APP_STORE_MODULE_NAME))
    store.registerModule(ARTICLE_APP_STORE_MODULE_NAME, articleStore);
    
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ARTICLE_APP_STORE_MODULE_NAME))
      store.unregisterModule(ARTICLE_APP_STORE_MODULE_NAME);
    });
    
    fetchCategories();

    store
      .dispatch("app-articles/fetchArticle", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        articleData.value = response.data;
        let loggedInUser = JSON.parse(localStorage.getItem("userData"));
        articleData.value.loggedInUser = loggedInUser;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          articleData.value = undefined;
        }
      });

    const {
      articleData,

      editArticleModal,
      editArticleId,
      editArticleTitle,
      editArticleCategories,
      editArticleContent,
      editArticleImage,

      updateArticle,
      deleteArticle,

      approveArticle,
      disapproveArticle,

      setStatus,
      setArticleStatus,
      enterStatusReasonModal,
      submitStatus,
      statusReason,
      requestChange,
    } = useArticlesList();

    const editArticle = () => {
      articleData.value.categories.forEach((category) => {
        editArticleCategories.value.push(category.id);
      });
      editArticleId.value = articleData.value.id;
      editArticleTitle.value = articleData.value.title;
      editArticleContent.value = articleData.value.content;

      editArticleModal.value = true;
    };
    
    return {
      articleData,
      avatarText,
      maxChar,

      categories,

      editArticleModal,
      editArticleId,
      editArticleTitle,
      editArticleCategories,
      editArticleContent,
      editArticleImage,

      editArticle,
      updateArticle,

      deleteArticle,

      approveArticle,
      disapproveArticle,

      setStatus,
      setArticleStatus,
      enterStatusReasonModal,
      submitStatus,
      statusReason,
      requestChange,
    };
  },
};
</script>

<style></style>
