import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useArticlesList() {
  // Use toast
  const toast = useToast();

  const refArticlesListTable = ref(null);

  const articleData = ref(null);
  const addArticleModal = ref(false);
  const editArticleModal = ref(false);

  // Table Handlers
  const tableColumns = [
    { key: "title", sortable: true },
    { key: "author", sortable: true },
    { key: "status" },
    { key: "uploaded-on", sortable: true },
    { key: "actions" },
  ];
  const per_page = ref(5);
  const totalArticles = ref(0);
  const current_page = ref(1);
  const per_pageOptions = [5, 10, 15, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  // Add article variables
  const addArticleTitle = ref("");
  const addArticleCategories = ref([]);
  const addArticleContent = ref("");
  const addArticleImage = ref(null);

  // Add article variables
  const editArticleId = ref("");
  const editArticleTitle = ref("");
  const editArticleCategories = ref([]);
  const editArticleContent = ref("");
  const editArticleImage = ref(null);

  const setStatus = ref('')
  const enterStatusReasonModal = ref(false);
  const statusReason = ref("");

  const dataMeta = computed(() => {
    const localItemsCount = refArticlesListTable.value
      ? refArticlesListTable.value.localItems.length
      : 0;
    return {
      from:
        per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalArticles.value,
    };
  });

  const refetchData = () => {
    refArticlesListTable.value.refresh();
  };

  watch([current_page, per_page, searchQuery], () => {
    refetchData();
  });

  const fetchArticles = (ctx, callback) => {
    store
      .dispatch("app-articles/fetchArticles", {
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then((response) => {
        const { data: articles, total } = response.data;

        callback(articles);
        totalArticles.value = total;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching articles list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const resolveArticleStatusVariant = (status) => {
    if (status === "Approved") return "success";
    if (status === "Awaiting Review") return "secondary";
    if (status === "Requires Action") return "warning";
    if (status === "Disapproved") return "danger";
    return "primary";
  };

  const storeArticles = (ctx, callback) => {
    const formData = new FormData();
    formData.append("title", addArticleTitle.value);
    formData.append("categories", addArticleCategories.value);
    formData.append("content", addArticleContent.value);
    formData.append("image", addArticleImage.value);
    store
      .dispatch("app-articles/addArticle", formData)
      .then(() => {
        refetchData();
        addArticleModal.value = false;
        toast({
          component: ToastificationContent,
          props: {
            title: "Article added.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while adding the article.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const updateArticle = () => {
    const formData = new FormData();
    formData.append("title", editArticleTitle.value);
    formData.append("categories", editArticleCategories.value);
    formData.append("content", editArticleContent.value);
    formData.append("image", editArticleImage.value);
    store
      .dispatch("app-articles/updateArticle", {
        id: editArticleId.value,
        data: formData,
      })
      .then((response) => {
        articleData.value = response.data.data;
        let loggedInUser = JSON.parse(localStorage.getItem("userData"));
        articleData.value.loggedInUser = loggedInUser;
        toast({
          component: ToastificationContent,
          props: {
            title: "Article updated.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        editArticleModal.value = false;
        editArticleId.value = "";
        editArticleTitle.value = "";
        editArticleCategories.value = [];
        editArticleContent.value = "";
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while updating the article.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const deleteArticle = (ctx, callback) => {
    store
      .dispatch("app-articles/deleteArticle", { id: articleData.value.id })
      .then(() => {
        router.push({ name: "apps-articles-list" });
        toast({
          component: ToastificationContent,
          props: {
            title: "Article deleted.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error deleting article.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const approveArticle = (ctx, callback) => {
    store
      .dispatch("app-articles/approveArticle", { id: articleData.value.id })
      .then((response) => {
        articleData.value = response.data;
        let loggedInUser = JSON.parse(localStorage.getItem("userData"));
        articleData.value.loggedInUser = loggedInUser;
        toast({
          component: ToastificationContent,
          props: {
            title: "Article status updated.",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while updating the article.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const setArticleStatus = (status) => {
    setStatus.value = status
    enterStatusReasonModal.value = true
  }

  const submitStatus = () => {
    if (setStatus.value == 'Disapprove') {
      disapproveArticle()
    } else {
      requestChange()
    }
  }

  const disapproveArticle = (ctx, callback) => {
    store
      .dispatch("app-articles/disapproveArticle", { id: articleData.value.id, data: {status_reason: statusReason.value} })
      .then((response) => {
        articleData.value = response.data;
        let loggedInUser = JSON.parse(localStorage.getItem("userData"));
        articleData.value.loggedInUser = loggedInUser;
        enterStatusReasonModal.value = false;
        statusReason.value = ''
        toast({
          component: ToastificationContent,
          props: {
            title: "Article status updated.",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while updating the article.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const requestChange = (ctx, callback) => {
    store
      .dispatch("app-articles/addCommentOnStatus", {
        id: articleData.value.id,
        data: {status_reason: statusReason.value},
      })
      .then((response) => {
        articleData.value = response.data;
        let loggedInUser = JSON.parse(localStorage.getItem("userData"));
        articleData.value.loggedInUser = loggedInUser;
        enterStatusReasonModal.value = false;
        statusReason.value = ''
        toast({
          component: ToastificationContent,
          props: {
            title: "Article status updated.",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while updating the article.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchArticles,
    storeArticles,
    updateArticle,
    deleteArticle,
    tableColumns,
    per_page,
    current_page,
    totalArticles,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refArticlesListTable,
    refetchData,

    articleData,
    editArticleModal,

    resolveArticleStatusVariant,

    addArticleModal,
    addArticleTitle,
    addArticleCategories,
    addArticleContent,
    addArticleImage,

    editArticleId,
    editArticleTitle,
    editArticleCategories,
    editArticleContent,
    editArticleImage,

    approveArticle,
    disapproveArticle,

    setStatus,
    setArticleStatus,
    enterStatusReasonModal,
    submitStatus,
    statusReason,
    requestChange,
  };
}
