import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchArticles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/articles", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchArticle(ctx, article) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/articles/" + article.id)
          .then((response) => resolve(response))
          .catch((error) => reject(error.response));
      });
    },
    addArticle(ctx, article) {
      return new Promise((resolve, reject) => {
        axios
          .post("admin/articles", article)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateArticle(ctx, article) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/articles/${article.id}/update`, article.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteArticle(ctx, article) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/articles/${article.id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    approveArticle(ctx, article) {
      return new Promise((resolve, reject) => {
        axios.get(`admin/articles/${article.id}/approve`)
            .then((response) => resolve(response))
            .catch((error) => reject(error))
      })
    },
    disapproveArticle(ctx, article) {
      return new Promise((resolve, reject) => {
        axios.post(`admin/articles/${article.id}/disapprove`, article.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addCommentOnStatus(ctx, article) {
      return new Promise((resolve, reject) => {
        axios.post(`admin/articles/${article.id}/request-change`, article.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchAllCategories(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/categories/all")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};